import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { getLog } from "../../api/apiLog";
import "./ShowLog.css";
const ShowLog = () => {
  let { name } = useParams();
  const [log, setLog] = useState({});
  const [status, setStatus] = useState(200);
  const getContentLog = async () => {
    try {
      const res = await axios.get(getLog + "/" + name);
      setLog(res.data);
      setStatus(res.status);
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToElement = () => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.getElementById(hash.slice(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  
  useEffect(() => {
    getContentLog();
    setTimeout(() => {
      scrollToElement();
    }, 3000);
  }, []);
 
  if (status === 200) {
    return (
      <div className="main">
        <div className="buttonOption">
          <Link to={"/"}>
            <button
              style={{
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
              }}
            >
              Home
            </button>
          </Link>
          <Link to={"/logs"}>
            {" "}
            <button
              style={{
                color: "white",
                backgroundColor: "blue",
                cursor: "pointer",
              }}
            >
              List of detected files
            </button>
          </Link>
          <span>{name}</span>
        </div>
        <div className="detectedDiv">
          <h3
            style={{
              display:
                log?.modelSpecial === "" && log?.issueItem === ""
                  ? "block"
                  : "none",
            }}
          >
            No errors were found in the file
          </h3>
          <h3 style={{ display: log?.modelSpecial !== "" ? "block" : "none" }}>
            Extra items:
          </h3>
          {log?.modelSpecial?.split("\n")?.reverse().map((line) => (
            <span>
              <span className="lineNumberLog">{line.split("|-|")[0]}</span>
              <span>{line.split("|-|")[1]}</span>
              <span className="itemDetected">
                <a
                  href={"#" + line.split("|-|")[0]}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {line.split("|-|")[2]}
                </a>
              </span>
              <span>{line.split("|-|")[3]}</span>
              <br></br>
            </span>
          ))}
          <h3 style={{ display: log?.issueItem !== "" ? "block" : "none" }}>
            Issue found:
          </h3>
          {log?.issueItem?.split("\n")?.reverse().map((line) => (
            <span>
              <span className="lineNumberLog">{line.split("|-|")[0]}</span>
              <span>{line.split("|-|")[1]}</span>
              <span className="itemDetected">
                <a
                  href={"#" + line.split("|-|")[0]}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {line.split("|-|")[2]}
                </a>
              </span>
              <span>{line.split("|-|")[3]}</span>
              <br></br>
            </span>
          ))}
        </div>
        <div className="logContent">
          {log?.contentFile?.split("\n")?.map((line) => (
            <span>
              <span className="lineNumberLog">{line.split("|-|")[0]}</span>
              <span>{line.split("|-|")[1]}</span>
              <span className="itemDetected">
                <span id={line.split("|-|")[0]}>{line.split("|-|")[2]}</span>
              </span>
              <span>{line.split("|-|")[3]}</span>
              <br></br>
            </span>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h1>
          <i>The file was not found.</i>
        </h1>
      </div>
    );
  }
};

export default ShowLog;
