import axios from "axios";
import React, { useEffect, useState } from "react";
import { getKeyValues } from "./api/apiLog";
import ManageValues from "./pages/ManageValues";
import { Routes, Route, Switch, useParams } from "react-router-dom";
import ShowLog from "./components/ShowLog/ShowLog";
import ListLog from "./components/ListLog/ListLog";
import ScreenShot from "./components/ScreenShot/ScreenShot";
function App() {
  return (
    <div className="App">
      <Routes>
        {/* Define routes */}
        <Route path="/" element={<ManageValues />}>
          {/* Render the UserProfile component */}
        </Route>
        <Route path="/logs/:name" element={<ShowLog />}></Route>
        <Route path="/logs" element={<ListLog />}></Route>
        <Route path="/screenShot/:name" element={<ScreenShot />}></Route>
      </Routes>
    </div>
  );
}

export default App;
